import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',    
    name: 'home',
    component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue')
  },
  {
    path: '/curriculum',
    name: 'curriculum',
    component: () => import(/* webpackChunkName: "curriculum" */ '../views/Curriculum.vue')
  },
  {
    path: '/empresas',
    name: 'empresas',
    component: () => import(/* webpackChunkName: "empresas" */ '../views/Empresas.vue')
  },
  {
    path: '/eventos',
    name: 'eventos',
    component: () => import(/* webpackChunkName: "eventos" */ '../views/Eventos.vue')
  },
  {
    path: '/pensamientos',
    name: 'pensamientos',
    component: () => import(/* webpackChunkName: "pensamientos" */ '../views/Pensamiento.vue')
  },
  {
    path: '/contacto',
    name: 'contacto',
    component: () => import(/* webpackChunkName: "contacto" */ '../views/Contacto.vue')
  },
  {
    path: '/lahistoriademicrowd',
    meta: {
      title: 'La historia de Microwd',
    },
    name: 'lahistoriademicrowd',
    component: () => import(/* webpackChunkName: "contacto" */ '../views/La-historia-de-microwd.vue')
  },
]

const router = new VueRouter({
  // mode: 'history',
  // base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if(to.meta.title) document.title = to.meta.title
  next()
})

export default router
