<template>
  <v-app>
    <div v-if="principal_page">
      <navbar></navbar>
      <v-content>
        <v-row id="row_content">
          <v-col cols="12" md="6">
            <div id="wrapp_content" class="d-flex align-center">
              <div id="wrapp_views" class="px-5 px-md-12 content_text">
                <router-view />
              </div>
            </div>
          </v-col>
          <v-col cols="5" class="d-none d-md-block">
            <Menu class="d-none d-md-flex"></Menu>
          </v-col>
        </v-row>
      </v-content>
      <Footer></Footer>
    </div>
    <div v-else>
      <router-view />
    </div>
  </v-app>
</template>

<script>
import { mapState } from 'vuex'
import navbar from "./components/Navbar";
import Menu from "./components/Menu";
import Footer from "./components/Footer";

export default {
  name: "App",
  components: {
    navbar,
    Menu,
    Footer
  },
  computed: {
    ...mapState(['principal_page'])
  }
};
</script>

<style>
  @import "./assets/css/general.css";
</style>