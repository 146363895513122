<template>
  <div id="wrapp_menu" class="align-center">
    <div id="wrapp_btns_footer" class="px-12">
      <v-row>
        <v-col cols="6" v-for="(btn, index) in btns" :key="index">
          <v-btn
            class="btn_menu py-1 px-2 text-capitalize"
            active-class="btn_menu_active"
            text
            :to="btn.to"
          >
            <b>{{ btn.text }}</b>
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "Menu",
  data: () => ({
    btns: [
      {
        text: "Home",
        to: "/"
      },
      {
        text: "CV",
        to: "/curriculum"
      },
      {
        text: "Empresas",
        to: "/empresas"
      },
      {
        text: "Eventos",
        to: "/eventos"
      },
      {
        text: "Pensamientos",
        to: "/pensamientos"
      },
      {
        text: "Contacto",
        to: "/contacto"
      }
    ]
  })
};
</script>

<style scoped>
.btn_menu {
  border: 2px #000000;
  border-style: dashed;
  border-radius: 28px;
  width: inherit;
}

.btn_menu_active {
  border: 2px #ffc72a;
  border-style: dashed;
}

.btn_menu_active::before {
  background-color: white;
}

#wrapp_menu {
  height: 100%;
}

#wrapp_btns_footer {
  margin: 0 auto;
}
</style>
