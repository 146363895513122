<template>
  <div id="wrapp_footer">
    <hr />
    <v-card id="card_footer" class="d-flex align-center">
      <span id="footer_name" class="mt-n5 mt-md-0">
        <span class="d-md-none vertical_align">
          <b>Ale</b>jandro de
          <b><span class="italic_text">León</span></b>
        </span>
        <img
          src="@/assets/gif_ale.gif"
          class="gif_ale mt-5 d-none d-md-block"
          alt="Imagen Alejandro de León"
        />
        <img
          src="@/assets/Ale.png"
          class="ml-3 ml-md-10 d-md-none vertical_align"
          alt="Imagen Alejandro de León"
        />
      </span>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data: () => ({})
};
</script>

<style scoped>
#wrapp_footer {
  position: fixed;
  bottom: 0;
  width: 100%;
}

#card_footer {
  height: 14vh;
}

#footer_name {
  margin: 0 auto;
  font-size: 1.5rem;
}

img {
  width: 35px;
}

.vertical_align {
  vertical-align:middle;
}

@media only screen and (min-width: 960px) {
  #card_footer {
    height: 24vh;
  }

  .gif_ale {
    width: 800px;
  }
}

@media only screen and (min-width: 600px) and (max-width: 960px) {
  #footer_name {
    font-size: 3rem;
  }
}
</style>
