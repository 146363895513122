<template>
  <div>
    <v-app-bar app height="35" color="#000000" dark>
      <v-app-bar-nav-icon
        class="d-md-none"
        @click="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <div class="d-none d-md-flex">
        <router-link to="/">
          <a
            ><h1 class="mr-2"><b>Ale</b>jandroDeLeón</h1></a
          >
        </router-link>
      </div>
      <v-spacer></v-spacer>
      <div class="d-flex align-center">
        <a
          class="mr-1 mr-md-5 pb-3 pb-md-0"
          href="https://aledeleonmoreno.com/kosmos/"
          target="_blank"
        >
          <h2 class="text-kosmos"><u>Kosmos</u></h2>
        </a>
        <a
          class="mr-3 mr-md-12 pb-3 pb-md-0"
          href="https://aledeleonmoreno.com/blog"
          target="_blank"
        >
          <h2 class="text-yellow"><u>100 ideas</u></h2>
        </a>
      </div>
      <div class="text-right text-md-right mt-3 mt-md-1">
        <a
          href="https://www.linkedin.com/in/alejandro-de-le%C3%B3n-moreno-a550821b/"
          target="_blank"
        >
          <img
            src="@/assets/icons/in.png"
            alt="linkedin icon"
            class="mr-2"
            width="18"
          />
        </a>
        <a href="https://twitter.com/Aledeleonmoreno" target="_blank">
          <img
            src="@/assets/icons/tw.png"
            alt="twitter icon"
            class="mr-2"
            width="18"
          />
        </a>
        <a
          href="https://www.ivoox.com/podcast-libertad-es-poder-hacer-lo-debemos_sq_f1850941_1.html"
          target="_blank"
        >
          <img
            src="@/assets/icons/po.png"
            alt="facebook icon"
            class="mr-2"
            width="18"
          />
        </a>
      </div>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" absolute temporary>
      <v-row>
        <v-col cols="12" class="mt-10 pa-7">
          <v-btn
            v-for="(btn, index) in btns"
            :key="index"
            class="btn_menu py-1 px-2 mb-3 text-capitalize"
            active-class="btn_menu_active"
            text
            :to="btn.to"
          >
            <b>{{ btn.text }}</b>
          </v-btn>
        </v-col>
      </v-row>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  name: "navbar",
  data: () => ({
    drawer: false,
    btns: [
      {
        text: "Home",
        to: "/",
      },
      {
        text: "CV",
        to: "/curriculum",
      },
      {
        text: "Empresas",
        to: "/empresas",
      },
      {
        text: "Eventos",
        to: "/eventos",
      },
      {
        text: "Pensamientos",
        to: "/pensamientos",
      },
      {
        text: "Contacto",
        to: "/contacto",
      },
    ],
  }),
};
</script>

<style scoped>
h1,
h2 {
  color: #ffc72a;
  font-size: 18px;
}

.text-fluor {
  color: #00f9ad;
}

.text-yellow {
  color: #ffc72a;
}

.text-kosmos {
  color: #3886d6;
}

.btn_menu {
  border: 2px #000000;
  border-style: dashed;
  border-radius: 28px;
  width: inherit;
}

.btn_menu_active {
  border: 2px #ffc72a;
  border-style: dashed;
}

.btn_menu_active::before {
  background-color: white;
}

@media only screen and (max-width: 960px) {
  h2 {
    font-size: 14px;
    margin-top: 14px;
  }
}
</style>
