import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    principal_page: true
  },
  mutations: {
    changeToSecondPage(state, bool) {
      state.principal_page = bool;
    }
  },
  actions: {
  },
  modules: {
  }
})
